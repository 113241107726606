import { Avatar, Box, Card, CardContent, Tab, Tabs, Theme, useMediaQuery } from '@mui/material';
import type { FC } from 'react';
import { useState } from 'react';
import { LoanPhaseDto, ViewType } from 'src/backend';
import { LENDER_VIEW } from 'src/constants/person';
import { useKanbanLoans } from 'src/contexts/loans-kanban-context';
import { viewTypeSelector } from 'src/slices/view';
import { useSelector } from 'src/store';
import { themeV2 } from 'src/theme/mysherpas-theme-option';
import { Loan } from 'src/types/loan';
import { getInitials } from 'src/utils/get-initials';

import { LoanKanbanPhase } from '../loan-kanban-phase/loan-kanban-phase';
import { LoansKanbanStyles } from './loans-kanban.styles';
import { LoansKanbanPhaseHeader } from './loans-kanban-phase-header';

const a11yProps = (index: string) => {
    return {
        id: `loan-status-tab-${index}`,
        'aria-controls': `loan-column-tabpanel-${index}`,
    };
}

const EMPTY_ARRAY = [];

interface TabPanelProps extends React.PropsWithChildren<any> {
    index: number;
    value: number;
    loans: Loan[];
    loanPhase: LoanPhaseDto;
    viewType: ViewType;
}

const TabPanel = (props: TabPanelProps) => {
    const { children, value, loans, index, loanPhase, viewType, ...other } = props;
    return (
        <Box
            sx={{
                '& .MuiPaper-root': {
                    flexDirection: 'column',
                }
            }}
            role="tabpanel"
            hidden={value !== index}
            id={`loan-column-tabpanel-${index}`}
            aria-labelledby={`loan-status-tab-${index}`}
            {...other}
        >
            {value === index && (
                <LoanKanbanPhase
                    loanPhase={loanPhase}
                    viewType={viewType}
                    loans={loans}
                />
            )}
        </Box>
    );
}

const LoansKanban: FC = () => {
    const [activeTab, setActiveTab] = useState<number>(0);
    const [focusedPhaseId, setFocusedPhaseId] = useState<string>(null);
    const viewType = useSelector(viewTypeSelector);
    const { state, onPinLoan } = useKanbanLoans();
    const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const isUserALender = viewType === LENDER_VIEW;
    const numberOfColumns = state.statuses.length;

    const handleChange = (_: any, newValue: number) => {
        setActiveTab(newValue);
    };

    const mobileView = <>
        <Card sx={{ position: 'sticky', top: 60 }}>
            <CardContent sx={{
                pb: 0,
                mb: 0,
                "&:last-child": {
                    paddingBottom: 0,
                    paddingTop: 1
                }
            }}>
                <Tabs
                    value={activeTab}
                    variant={!smDown ? 'scrollable' : 'fullWidth'}
                    onChange={handleChange}
                    aria-label="basic tabs example">
                    {state.statuses.map((loanPhase) =>
                        <Tab
                            key={loanPhase.id}
                            icon={<Avatar sx={{ width: 24, height: 24 }}>{getInitials(loanPhase.id)}</Avatar>}
                            label={loanPhase.name.toLowerCase()}
                            {...a11yProps(loanPhase.id)} />)}
                </Tabs>
            </CardContent>
        </Card>
        {state.statuses.map((loanPhase, index) => (
            <TabPanel
                loans={state.loans?.[loanPhase.id] ?? EMPTY_ARRAY}
                value={activeTab}
                index={index}
                key={loanPhase.name}
                viewType={viewType}
                loanPhase={loanPhase}
            />
        ))}
    </>


    if (smDown) {
        return mobileView
    }

    return (<LoansKanbanStyles.Container
        maxWidth={false}
        className='loans-kanban-container'
        data-is-focused={!!focusedPhaseId}
        data-four-cols={numberOfColumns >= 4}>
        <Box sx={{
            pt: 4,
            backgroundColor: 'transparent',
            position: 'sticky',
            top: 0,
            zIndex: 4,
            display: 'flex',

            gap: !!focusedPhaseId ? 0 : 4,
            '&:before': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                height: 32,
                backgroundColor: themeV2.neutral.grey[7],
            }
        }}>
            {state.statuses.map((loanPhase) => (<LoansKanbanPhaseHeader
                focusMode={!!focusedPhaseId}
                key={loanPhase.id}
                loanPhase={loanPhase}
                isFocused={!!focusedPhaseId && loanPhase.id === focusedPhaseId}
                onFocus={setFocusedPhaseId}
            />
            ))}
        </Box>
        <Box
            sx={{
                display: 'flex',
                gap: !!focusedPhaseId ? 0 : 4,
                position: 'relative',
                height: '100%',
            }}>
            {state.statuses.map((loanPhase) => (
                <LoanKanbanPhase
                    className={focusedPhaseId && (loanPhase.id === focusedPhaseId ? 'is-focused' : 'is-not-focused')}
                    isDragEnabled={(!focusedPhaseId && isUserALender)}
                    viewType={viewType}
                    onPinLoan={onPinLoan}
                    loanPhase={loanPhase}
                    loans={state.loans?.[loanPhase.id] ?? EMPTY_ARRAY}
                    key={loanPhase.id}
                />
            ))}
        </Box>
    </LoansKanbanStyles.Container>
    );
};

export default LoansKanban;
