import React from "react";
import { AppUserDTO2, AppUserSignupRequestDto } from "src/backend";
import { editUser, getLenderEmployees, lenderEmployeesListSelector } from "src/slices/lender";
import { useDispatch, useSelector } from "src/store";

interface Props {
    lenderId: string;
    skip?: boolean;
}

export const useLenderEmployees = ({ lenderId, skip }: Props) => {
    const [loading, setLoading] = React.useState<boolean>(false);
    const dispatch = useDispatch();
    const employeesList: AppUserDTO2[] = useSelector(lenderEmployeesListSelector(lenderId));

    const refresh = React.useCallback(() => {
        if (!!lenderId && !skip) {
            dispatch(getLenderEmployees({ lenderId }))
        }
    }, [dispatch, lenderId, skip]);

    React.useEffect(() => {
        refresh();
    }, [refresh]);

    const onUpdate = React.useCallback(async (user: AppUserSignupRequestDto & { id: string }) => {
        setLoading(true);
        try {
            await dispatch(editUser(user.id, user));

        } catch (e) { }
        setLoading(false);
    }, [dispatch]);

    return {
        loading,
        employees: employeesList,
        onUpdate,
        refresh
    }
}