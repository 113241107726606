import { j as r } from "./jsx-runtime-944c88e2.js";
import "./Accordion.es.js";
import "./Avatar.es.js";
import j from "./Badge.es.js";
import "./Button.es.js";
import "./DropdownMenu.es.js";
import s from "./Icon.es.js";
import "./Input.es.js";
import "./Label.es.js";
import a from "./Text.es.js";
import "./Tooltip.es.js";
import { g as m, c as g } from "./utils-428888d8.js";
import e from "./Stack.es.js";
import "./Switch.es.js";
import "./Toast-a44f0fc5.js";
import "react";
import "./Toggle.es.js";
import "./index-bf9f2594.js";
import "./index-61f4edff.js";
import "./index-a88fc5a6.js";
import "./index-5499c00c.js";
import "./index-14f22d4f.js";
import "react-dom";
import "./index-e1c48dc5.js";
import "./index-7ed7ad30.js";
import "./index-1fe761a6.js";
import "iconoir-react";
import "./index-31854cc2.js";
import "./index-6b71901e.js";
import "./index-760cd071.js";
import "./index-62dc40ef.js";
import "./floating-ui.react-dom-c6515077.js";
import "./index-a1ad2cc2.js";
import "./index-1ac05127.js";
import "./Combination-ba9973a9.js";
import "./constants-cf1341a1.js";
import "./index-6460524a.js";
const i = ({
  children: o,
  className: t = ""
}) => {
  const f = m(o, "Name"), y = m(
    o,
    "Icon",
    /* @__PURE__ */ r.jsx(s, { name: "ClientFolder", width: 20 })
  ), x = m(o, "PrimaryInfo"), I = m(o, "SecondaryInfo"), N = m(o, "Badge");
  return /* @__PURE__ */ r.jsxs(
    e,
    {
      row: !0,
      space: "md",
      className: g(
        "p-4 bg-white w-fit folder-border rounded-md md:min-w-[360px]",
        t
      ),
      children: [
        /* @__PURE__ */ r.jsx(e, { row: !0, space: "md", className: "items-start", children: y }),
        /* @__PURE__ */ r.jsxs(e, { space: "md", children: [
          f,
          /* @__PURE__ */ r.jsxs(e, { row: !0, space: "md", className: "items-center", children: [
            x,
            I,
            N
          ] })
        ] })
      ]
    }
  );
}, p = ({ name: o, ...t }) => /* @__PURE__ */ r.jsx(s, { name: o, width: 20, height: 20, ...t });
p.displayName = "Icon";
i.Icon = p;
const n = ({ children: o }) => /* @__PURE__ */ r.jsx(a, { size: "base", className: "leading-5", children: o });
n.displayName = "Name";
i.Name = n;
const d = ({ children: o }) => /* @__PURE__ */ r.jsx(a, { size: "xs", variant: "primary", children: o });
d.displayName = "PrimaryInfo";
i.PrimaryInfo = d;
const c = ({ children: o }) => /* @__PURE__ */ r.jsx(a, { size: "xs", variant: "secondary", children: o });
c.displayName = "SecondaryInfo";
i.SecondaryInfo = c;
const l = ({
  children: o,
  className: t
}) => /* @__PURE__ */ r.jsx(j, { className: t, variant: "destructive", children: o });
l.displayName = "Badge";
i.Badge = l;
export {
  i as default
};
