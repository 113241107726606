import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableSortLabel from '@mui/material/TableSortLabel';
import Tooltip from 'components/dist/atoms/Tooltip';
import * as React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { LoanPhaseDto } from 'src/backend';
import { DndProvider } from 'src/providers/dnd-provider';
import { Loan } from 'src/types/loan';

import { LoanListViewRowSkeleton } from './loan-list-view-row-skeleton';
import { useLoanListViewTable } from './loan-list-view-table.hook';
import { LoanListViewTableStyles } from './loan-list-view-table.styles';
import { LoanListViewTableRow } from './loan-list-view-table-row';
import { LoanListViewTableTotalRow } from './loan-list-view-table-total-row';

interface LoanListViewTableProps {
    loans: Loan[];
    loanPhase: LoanPhaseDto;
}

export const LoanListViewTable = (props: LoanListViewTableProps) => {
    const {
        onDragEnd,
        onSortByColumn,
        onPinLoan,
        columns,
        orderDirection,
        orderByColumn,
        selectedHeaders,
    } = useLoanListViewTable();

    const viewableColumns = columns.filter((column) => 
        selectedHeaders.find((header) => header.id === column.id));

    return (
        <DndProvider>
            <TableContainer>
                <LoanListViewTableStyles.Table aria-label="loans table" data-table-fixed="true">
                    <TableHead>
                        {/* @ts-expect-error */}
                        <DragDropContext onDragEnd={onDragEnd}>
                            {/* @ts-expect-error */}
                            <Droppable
                                droppableId="droppable"
                                direction="horizontal"
                            >
                                {(provided) => (
                                    <LoanListViewTableStyles.TableRow
                                        data-head="true"
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                    >
                                        <LoanListViewTableStyles.TableCell data-limited-width="true">
                                            Info
                                        </LoanListViewTableStyles.TableCell>
                                        {viewableColumns.map(
                                            (column, index) => {
                                              // In order to sort with the info
                                              // view elements we need its index
                                              // out of all existent columns
                                              const indexOutOfAllElements = columns.findIndex((col) => col.id === column.id);

                                              return (
                                                // @ts-expect-error
                                                <Draggable
                                                    key={column.id}
                                                    draggableId={column.id}
                                                    index={indexOutOfAllElements + 1}
                                                >
                                                    {(dragProvided) => (
                                                        <LoanListViewTableStyles.TableCell
                                                            ref={
                                                                dragProvided.innerRef
                                                            }
                                                            key={column.id}
                                                            align={column.align || 'left'}
                                                            data-width={viewableColumns.length < 6 ? 'content' : 'full'}
                                                            {...dragProvided.draggableProps}
                                                            {...dragProvided.dragHandleProps}
                                                        >
                                                            <TableSortLabel
                                                                sx={{ width: '100%' }}
                                                                disabled={
                                                                    !column.sortable
                                                                }
                                                                direction={
                                                                    orderDirection ??
                                                                    'asc'
                                                                }
                                                                active={
                                                                    orderByColumn ===
                                                                    column.id
                                                                }
                                                                onClick={() =>
                                                                    onSortByColumn(
                                                                        column.id
                                                                    )
                                                                }
                                                            >
                                                              <Tooltip.Provider>
                                                                <Tooltip>
                                                                  <Tooltip.Trigger asChild>
                                                                    <span className="truncate w-fit">{column.label}</span>
                                                                  </Tooltip.Trigger>
                                                                  <Tooltip.Portal>
                                                                    <Tooltip.Content>
                                                                      {column.label}
                                                                    </Tooltip.Content>
                                                                  </Tooltip.Portal>
                                                                </Tooltip>
                                                              </Tooltip.Provider>
                                                            </TableSortLabel>
                                                        </LoanListViewTableStyles.TableCell>
                                                    )}
                                                </Draggable>
                                            )}
                                        )}
                                        {viewableColumns.length < 6 && (
                                            <LoanListViewTableStyles.TableCell data-limited-width="true" />
                                        )}
                                        {provided.placeholder}
                                    </LoanListViewTableStyles.TableRow>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </TableHead>
                    <TableBody>
                        {props.loans?.map((loan) => (
                            <LoanListViewTableRow
                                onPinLoan={onPinLoan}
                                columns={viewableColumns}
                                isPinned={loan.pinned}
                                loan={loan}
                                key={loan.id}
                            />
                        ))}
                        <LoanListViewRowSkeleton
                            key={props.loans?.length}
                            loanPhase={props.loanPhase}
                            columns={viewableColumns}
                        />
                        <LoanListViewTableTotalRow
                            loans={props.loans}
                            columns={viewableColumns}
                        />
                    </TableBody>
                </LoanListViewTableStyles.Table>
            </TableContainer>
        </DndProvider>
    );
};
