import { Link } from "@mui/material"
import { styled } from "@mui/system"

const LoanCardLink = styled(Link)(({ theme }) => ({
    width: '100%',
    textDecoration: 'none',
    '&:hover': {
        textDecoration: 'none',
    }
}))

export const LoanKanbanPhaseStyles = {
    LoanCardLink
}