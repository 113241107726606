import { j as l } from "./jsx-runtime-944c88e2.js";
import * as p from "react";
import { c } from "./utils-428888d8.js";
import { c as x } from "./index-31854cc2.js";
const f = x("flex", {
  variants: {
    row: {
      true: "flex-row"
    },
    col: {
      true: "flex-col"
    },
    space: {
      xs: "gap-1",
      sm: "gap-2",
      default: "gap-3",
      md: "gap-4",
      lg: "gap-6",
      xl: "gap-8",
      xxl: "gap-12",
      xxxl: "gap-16"
    },
    defaultVariants: {
      row: "",
      col: "",
      space: "default"
    }
  }
}), m = p.forwardRef(({ children: t, space: r, row: a, className: o, ...s }, e) => /* @__PURE__ */ l.jsx(
  "div",
  {
    ref: e,
    className: c(f({ row: a, col: !a, space: r, className: o })),
    ...s,
    children: t
  }
));
m.displayName = "Stack";
export {
  m as default
};
