import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses, TooltipProps } from "@mui/material/Tooltip";
import { themeV2 } from "src/theme/mysherpas-theme-option";

const DarkTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: themeV2.colors.blue[900]
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: themeV2.colors.blue[900]
    },
    ['& [data-light="true"]']: {
        [`&.${tooltipClasses.tooltip}`]: {
            backgroundColor: 'white',
            color: themeV2.colors.black.neutral,
            boxShadow: theme.shadows[4],
        },
        [`& .${tooltipClasses.arrow}`]: {
            color: 'white'
        },
    }
}));

export const MysherpasTooltipStyles = {
    DarkTooltip
}