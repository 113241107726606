import { FullscreenExit } from '@mui/icons-material';
import { Box, IconButton, Paper, Tooltip, Typography } from "@mui/material"
import { LoanPhaseDto } from "src/backend"
import { DragIcon } from 'src/icons/drag-icon';
import { loanPhaseLoansCountSelector } from 'src/slices/loan-manager';
import { useSelector } from 'src/store';
import { themeV2 } from "src/theme/mysherpas-theme-option"

import { LoanListViewStyles } from "../loans/loan-list-view/loan-list-view.styles"

interface LoansKanbanPhaseHeaderProps {
    loanPhase: LoanPhaseDto
    isFocused: boolean
    focusMode: boolean
    onFocus: (id: string | null) => void

}

export const LoansKanbanPhaseHeader = (props: LoansKanbanPhaseHeaderProps) => {
    const totalLoans = useSelector(loanPhaseLoansCountSelector(props.loanPhase.id))

    const handleToggle = () => {
        props.onFocus(props.isFocused ? null : props.loanPhase.id)
    }


    return <Paper
        className={props.focusMode ? (props.isFocused ? 'is-focused' : 'is-not-focused') : ""}
        sx={{
            cursor: 'pointer',
            boxShadow: 'none',
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            height: '100%',
        }}
        onDoubleClick={handleToggle} >
        <Box
            sx={{

                display: {
                    xs: 'none',
                    sm: 'flex',
                },
                alignItems: 'center',
                py: 1,
                backgroundColor: 'white',

            }}>
            <Typography
                data-testid={`kanban-column-title-${props.loanPhase.name}`}
                component='div'
                sx={{
                    flex: 1,
                    color: themeV2.colors.blue[900],
                    typography: 'subtitle2',
                    gap: 2,
                    display: 'flex'
                }}>
                <LoanListViewStyles.Chip label={totalLoans} />
                {props.loanPhase.name}
            </Typography>
            {props.isFocused && <Tooltip title='Restore' arrow disableInteractive>
                <IconButton
                    onClick={handleToggle}
                    color='inherit'>
                    <FullscreenExit />
                </IconButton>
            </Tooltip>}
            {!props.isFocused && <Tooltip title='Maximize' arrow disableInteractive>
                <IconButton
                    onClick={handleToggle}
                    color='inherit'
                >
                    <DragIcon />
                </IconButton>
            </Tooltip>}
        </Box>
    </Paper>
}