import * as React from "react"

export const CommunityIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={25}
        height={24}
        viewBox="0 0 25 24"
        fill="none"
        {...props}
    >
        <g clipPath="url(#aCommunityIcon)">
            <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M7.5 18v-1a5 5 0 0 1 5-5m0 0a5 5 0 0 1 5 5v1m-5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm-11 6v-1a3 3 0 0 1 3-3m0 0a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm19 4v-1a3 3 0 0 0-3-3m0 0a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"
            />
        </g>
        <defs>
            <clipPath id="aCommunityIcon">
                <path fill="#fff" d="M.5 0h24v24H.5z" />
            </clipPath>
        </defs>
    </svg>
)
