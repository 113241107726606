import { useRouter } from "next/router";
import { Route } from 'src/constants/ui';
import { Loan, LoanTableColumn } from "src/types/loan";
import { capitalizeString } from "src/utils/capitalize-string";
import { LoanCalculations } from "src/utils/loan/calculations/loan-calculations";
import { formatAsCurrency } from "src/utils/money-formatter";
import { isRoleABorrower } from "src/utils/user/is-role-a-borrower";

import { LoanProgressBar } from "../loan-progress-bar";
import { RolesAvatars } from "../roles-avatars";

interface LoanListViewTableRowProps {
    loan: Loan;
    columns: LoanTableColumn[];
}

export const useLoanListViewTableRow = (props: LoanListViewTableRowProps) => {
    const { push } = useRouter();

    const handleRowClick = () => {
        push({
            pathname: Route.SINGLE_LOAN,
            query: {
                loanId: props.loan.id
            }
        })
    }

    const columnsWithValues = getLoanColumnsWithValues({ loan: props.loan, columns: props.columns })

    return {
        columns: columnsWithValues,
        onRowClick: handleRowClick,
    }
};

export const getLoanColumnsWithValues = ({ loan, columns }: { loan: Loan, columns: LoanTableColumn[] }): LoanTableColumn[] => {
    const calculations = new LoanCalculations(loan);

    return columns.map((column) => {
        if (["downPayment", "loanAmount"].includes(column.id)) {
            return {
                ...column,
                value: loan[column.id],
                formattedValue: formatAsCurrency(loan[column.id])
            }
        }
        if (column.id === "downPaymentAmount" && loan[column.id]) {
            return {
                ...column,
                value: loan[column.id],
                formattedValue: formatAsCurrency(loan[column.id])
            }
        }
        if (column.id === "downPaymentPercentage" && loan[column.id]) {
            return {
                ...column,
                value: loan[column.id],
                formattedValue: `${loan.downPaymentPercentage ?? "??"}%`
            }
        }
        if (column.id === "downPaymentSource" && loan[column.id]) {
            return {
                ...column,
                value: loan.downPaymentSource,
                formattedValue: `${(loan.downPaymentSource !== 'Select' && loan.downPaymentSource) ? loan.downPaymentSource : '??'}`
            }
        }
        if (column.id === "loanOriginationPoints" && loan[column.id]) {
            return {
                ...column,
                value: loan.loanOriginationPoints,
                formattedValue: `${loan.loanOriginationPoints ?? "??"}%`
            }
        }
        if (column.id === "loanOriginationFees" && loan[column.id]) {
            return {
                ...column,
                value: loan.loanOriginationFees,
                formattedValue: formatAsCurrency(loan.loanOriginationFees)
            }
        }
        if (column.id === "loanOtherFees" && loan[column.id]) {
            return {
                ...column,
                value: loan.loanOtherFees,
                formattedValue: formatAsCurrency(loan.loanOtherFees)
            }
        }
        if (column.id === "apr" && !!loan[column.id]) {
            return {
                ...column,
                value: loan[column.id],
                formattedValue: `${loan[column.id].toFixed(2)}%`
            }
        }
        if (column.id === "actualLoanTerm" && loan[column.id]) {
            return {
                ...column,
                value: loan[column.id],
                formattedValue: `${loan[column.id]} mos.`
            }
        }
        if (column.id === "loanTermInYears") {
            return {
                ...column,
                value: calculations.getLoanTermInYears(),
                formattedValue: `${calculations.getLoanTermInYears()} yrs.`
            }
        }
        if (column.id === 'ltv') {
            return {
                ...column,
                value: calculations.getLoanCLTV(),
                formattedValue: `${calculations.getLoanCLTV().toFixed(1)}%`,
            }
        }
        if (column.id === 'purchasePrice') {
            return {
                ...column,
                value: calculations.getTotalEntitiesPurchasePrice(),
                formattedValue: formatAsCurrency(calculations.getTotalEntitiesPurchasePrice())
            }
        }
        if (column.id === 'pmla') {
            return {
                ...column,
                value: calculations.getLoanEntitiesCMla(),
                formattedValue: formatAsCurrency(calculations.getLoanEntitiesCMla())
            }
        }
        if (column.id === 'totalCosts') {
            return {
                ...column,
                value: calculations.getLoanEntitiesTotalCost(),
                formattedValue: formatAsCurrency(calculations.getLoanEntitiesTotalCost())
            }
        }

        if (column.id === 'ltc') {
            return {
                ...column,
                value: calculations.getLoanLTC(),
                formattedValue: `${calculations.getLoanLTC().toFixed(1)}%`
            }
        }
        if (column.id === 'closeDate') {
            return {
                ...column,
                value: loan.closeDateFormatted,
                formattedValue: loan.closeDateFormatted
            }
        }
        if (column.id === 'projectCosts') {
            return {
                ...column,
                value: calculations.getProjectCosts(),
                formattedValue: formatAsCurrency(calculations.getProjectCosts())
            }
        }
        if (column.id === 'loanID') {
            return {
                ...column,
                value: loan.shortCode,
                formattedValue: loan.shortCode
            }
        }
        if (column.id === 'loanType') {
            return {
                ...column,
                value: loan.templateProjectName,
                formattedValue: loan.templateProjectName
            }
        }
        if (column.id === 'dateCreated') {
            return {
                ...column,
                value: loan.createdDateFormatted,
                formattedValue: loan.createdDateFormatted
            }
        }
        if (column.id === 'loanStatus') {
            return {
                ...column,
                value: loan.loanPhase?.name?.toLowerCase(),
                formattedValue: capitalizeString(loan.loanPhase?.name)
            }
        }
        if (column.id === 'lenders') {
            return {
                ...column,
                value: null,
                formattedValue: <RolesAvatars roles={loan.loanRoles.filter(loanRole => !isRoleABorrower(loanRole.role))} />
            }
        }
        if (column.id === 'applicants') {
            return {
                ...column,
                value: null,
                formattedValue: <RolesAvatars roles={loan.loanRoles.filter(loanRole => isRoleABorrower(loanRole.role))} />
            }
        }
        if (column.id === 'loanTermInMonths') {
            return {
                ...column,
                value: loan.loanTermInMonths,
                formattedValue: `${loan.loanTermInMonths ?? "??"} mos.`
            }
        }
        if (column.id === 'amortizationInMonths') {
            return {
                ...column,
                value: calculations.getRequestedLoanAmortizationInYears(),
                formattedValue: `${calculations.getRequestedLoanAmortizationInYears()} yrs.`
            }
        }
        if (column.id === 'amortization') {
            return {
                ...column,
                value: calculations.getLoanTermInYears(),
                formattedValue: `${calculations.getLoanTermInYears()} yrs.`
            }
        }
        if (column.id === 'loanProgress') {
            return {
                ...column,
                value: null,
                formattedValue: <LoanProgressBar loan={loan} />
            }
        }
        if (column.id === 'requestedLoanAmount') {
            return {
                ...column,
                value: loan.loanAmount,
                formattedValue: formatAsCurrency(loan.loanAmount)
            }
        }
        if (column.id === 'actualLoanAmount') {
            return {
                ...column,
                value: loan.actualLoanAmount,
                formattedValue: formatAsCurrency(loan.actualLoanAmount)
            }
        }
        if (['index', 'margin'].indexOf(column.id) > -1) {
            return {
                ...column,
                value: loan[column.id],
                formattedValue: `${loan[column.id] ?? '??'}%`
            }
        }
        return {
            ...column,
            value: loan[column.id],
            formattedValue: loan[column.id]
        }
    });

}
