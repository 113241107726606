import { j as o } from "./jsx-runtime-944c88e2.js";
import { c as m } from "./utils-428888d8.js";
import "./Accordion.es.js";
import "./Avatar.es.js";
import "./Badge.es.js";
import "./Button.es.js";
import "./DropdownMenu.es.js";
import "./Icon.es.js";
import "./Input.es.js";
import "./Label.es.js";
import s from "./Text.es.js";
import "./Tooltip.es.js";
import n from "./Stack.es.js";
import "./Switch.es.js";
import "./Toast-a44f0fc5.js";
import "react";
import "./Toggle.es.js";
import "./index-bf9f2594.js";
import "./index-61f4edff.js";
import "./index-a88fc5a6.js";
import "./index-5499c00c.js";
import "./index-14f22d4f.js";
import "react-dom";
import "./index-e1c48dc5.js";
import "./index-7ed7ad30.js";
import "./index-1fe761a6.js";
import "iconoir-react";
import "./index-31854cc2.js";
import "./index-6b71901e.js";
import "./index-760cd071.js";
import "./index-62dc40ef.js";
import "./floating-ui.react-dom-c6515077.js";
import "./index-a1ad2cc2.js";
import "./index-1ac05127.js";
import "./Combination-ba9973a9.js";
import "./constants-cf1341a1.js";
import "./index-6460524a.js";
const i = ({ children: r, className: t, col: e }) => /* @__PURE__ */ o.jsx(
  "li",
  {
    className: m(
      "border-b border-gray-card-border last:border-0 mt-8 first:mt-0 pb-1",
      t
    ),
    children: /* @__PURE__ */ o.jsx(n, { row: !e, className: "justify-between", children: r })
  }
);
i.displayName = "LoanCardItem";
const a = ({ children: r, className: t }) => /* @__PURE__ */ o.jsx(s, { size: "sm", className: m("text-gray-neutral-70", t), children: r });
a.displayName = "LoanCardItemTitle";
const p = ({ children: r, className: t }) => /* @__PURE__ */ o.jsx("div", { className: m(t), children: r });
p.displayName = "LoanCardItemValue";
const d = ({ className: r, children: t }) => /* @__PURE__ */ o.jsx("ul", { className: m("bg-white-neutral rounded-md p-4", r), children: t }), W = Object.assign(d, {
  Item: i,
  Title: a,
  Value: p
});
export {
  W as default
};
