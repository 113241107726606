import { type TooltipProps } from "@mui/material/Tooltip";

import { MysherpasTooltipStyles } from "./mysherpas-tooltip.styles";

export const MysherpasTooltip = (props: TooltipProps & { light?: boolean }) => {
    const { title, children, light, ...other } = props;

    if (props.hidden) return <>{children}</>

    return <MysherpasTooltipStyles.DarkTooltip
        componentsProps={{
            tooltip: {
                //@ts-expect-error
                'data-light': light
            }
        }}
        title={title}
        disableInteractive
        placement='top'
        arrow {...other} >
        {children}
    </MysherpasTooltipStyles.DarkTooltip>
}