import { LinearProgress } from "@mui/material";
import { useKanbanLoans } from "src/contexts/loans-kanban-context";
import { LoanDisplayView } from "src/types/loan";

import { LoanListView } from "../loans/loan-list-view";
import LoansKanban from "./loans-kanban";

export const LoansKanbanMapper = () => {
    const { state } = useKanbanLoans();

    if (state.isLoading) {
        return <LinearProgress />;
    }

    if (state.displayView === LoanDisplayView.grid) {
        return <LoansKanban />;
    }

    return <LoanListView />;
};