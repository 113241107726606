import { useState } from "react";
import { useInView } from "react-intersection-observer";
import { LoanPhaseCategoryType, LoanPhaseDto } from "src/backend";
import { LoansKanbanFilter } from "src/constants/loan";
import { VirtualLoanPhase } from "src/constants/loan-phase";
import { PRINCIPAL_VIEW } from "src/constants/person";
import { useMounted } from "src/hooks/use-mounted";
import { useUser } from "src/hooks/use-user";
import { getLoans, loanPhaseMaxPageSelector } from "src/slices/loan-manager";
import { loansFilterSelector } from "src/slices/ui";
import { viewTypeSelector } from "src/slices/view";
import { useDispatch, useSelector } from "src/store";
import { filterKanbanLoans } from "src/utils/loan/filter-kanban-loans";

interface LoanKanbanPhaseHookProps {
    loanPhase: LoanPhaseDto;
}

export const useLoanKanbanPhase = (props: LoanKanbanPhaseHookProps): {
    isLoading: boolean;
    loadMoreNode: (node?: Element) => void;
} => {
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const currentPage = useSelector(loanPhaseMaxPageSelector(props.loanPhase.id));
    const isMounted = useMounted();
    const { user, isUnderwriter } = useUser();
    const filterState = useSelector(loansFilterSelector);
    const viewType = useSelector(viewTypeSelector);
    const isUserABorrower = viewType === PRINCIPAL_VIEW;
    const handleLoadMore = async (inView: boolean, page: number) => {
        if (inView) {
            (async () => {
                setIsLoading(true);
                const virtualKeys = Object.keys(VirtualLoanPhase);
                // if loanPhase id is one of the virtual loan phases, then we don't need to pass in the phaseId
                // we are going to pass the virtual loan phase id as phaseCategory
                const phaseId = virtualKeys.includes(props.loanPhase.id) ? undefined : props.loanPhase.id;
                const phaseCategory = virtualKeys.includes(props.loanPhase.id) ? props.loanPhase.id as LoanPhaseCategoryType : undefined;
                const result = await dispatch(getLoans({
                    page: page + 1,
                    phaseId,
                    phaseCategory,
                }));

                // if the current view filter is my loans
                // and after filtering loans, there is no loan left
                // then we need to fetch the next page
                const allFilteredLoans = result.loans
                    .filter(loan => filterKanbanLoans({
                        loan,
                        isUserABorrower,
                        isUnderwriter,
                        filterState,
                        userId: user.id,
                    }));
                // not on last page and we got no loans
                // and filter is lender loans
                if (filterState.loansFilter === LoansKanbanFilter.LENDER_LOANS &&
                    allFilteredLoans.length === 0 &&
                    !result.lastPage) {
                    await handleLoadMore(inView, page + 1);
                }
                if (isMounted()) {
                    setIsLoading(false);
                }
            })();
        }
    }

    const [loadMoreNode] = useInView({
        threshold: 0,
        triggerOnce: false,
        onChange: (inView: boolean) => handleLoadMore(inView, currentPage),
    });

    return {
        isLoading,
        loadMoreNode,
    }
}