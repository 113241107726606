import { LoanReviewStatus } from "src/backend";
import { useKanbanLoans } from "src/contexts/loans-kanban-context";

import { LoanListViewStyles } from "./loan-list-view.styles"
import { LoanListViewPhase } from "./loan-list-view-phase-card";

export const LoanListView = () => {
    const { state } = useKanbanLoans();
    const atLeastOneLoanHasReviewStatus = Object.values(state.loans ?? {}).some(statusLoans => statusLoans.some(loan => loan.reviewStatus !== LoanReviewStatus.IN_PROGRESS));
    return <LoanListViewStyles.Wrapper data-hide-statuses={!atLeastOneLoanHasReviewStatus}>
        <LoanListViewStyles.Container maxWidth="xxl">
            {state.statuses.map(status => {
                return (<LoanListViewPhase
                    key={status.id}
                    phase={status}
                    loans={state.loans?.[status.id]} />)
            })}
        </LoanListViewStyles.Container>
    </LoanListViewStyles.Wrapper>
}