import { LoanReviewStatus } from "src/backend";
import { MysherpasTooltip } from "src/components/common/mysherps-tooltip";
import { getReviewStatusChipLabel } from "src/utils/loan/get-review-status-chip-label";
import { getReviewStatusTooltip } from "src/utils/loan/get-review-status-tooltip";

import { LoanReviewChipStyles } from "./loan-review-chip.styles";

interface LoanReviewChipProps {
    status: LoanReviewStatus;
}

export const LoanReviewChip = (props: LoanReviewChipProps) => {
    if (props.status === LoanReviewStatus.IN_PROGRESS || !props.status) {
        return null;
    }

    return (<MysherpasTooltip title={getReviewStatusTooltip(props.status)}>
        <LoanReviewChipStyles.Wrapper
            data-status={props.status} >
            {getReviewStatusChipLabel(props.status)}
        </LoanReviewChipStyles.Wrapper>
    </MysherpasTooltip>)
};