import { j as t } from "./jsx-runtime-944c88e2.js";
import "./Accordion.es.js";
import "./Avatar.es.js";
import h from "./Badge.es.js";
import "./Button.es.js";
import e from "./DropdownMenu.es.js";
import n from "./Icon.es.js";
import "./Input.es.js";
import "./Label.es.js";
import c from "./Text.es.js";
import "./Tooltip.es.js";
import { g as s } from "./utils-428888d8.js";
import p from "./Stack.es.js";
import "./Switch.es.js";
import "./Toast-a44f0fc5.js";
import "react";
import "./Toggle.es.js";
import "./index-bf9f2594.js";
import "./index-61f4edff.js";
import "./index-a88fc5a6.js";
import "./index-5499c00c.js";
import "./index-14f22d4f.js";
import "react-dom";
import "./index-e1c48dc5.js";
import "./index-7ed7ad30.js";
import "./index-1fe761a6.js";
import "iconoir-react";
import "./index-31854cc2.js";
import "./index-6b71901e.js";
import "./index-760cd071.js";
import "./index-62dc40ef.js";
import "./floating-ui.react-dom-c6515077.js";
import "./index-a1ad2cc2.js";
import "./index-1ac05127.js";
import "./Combination-ba9973a9.js";
import "./constants-cf1341a1.js";
import "./index-6460524a.js";
const i = ({
  children: o,
  className: m = ""
}) => {
  const r = s(o, "Top"), a = s(o, "Bottom");
  return /* @__PURE__ */ t.jsxs(
    "div",
    {
      className: `${m} bg-gray-100 flex flex-col rounded-lg overflow-hidden w-fit`,
      children: [
        r,
        a
      ]
    }
  );
}, l = ({ children: o, className: m }) => {
  const r = s(o, "Image"), a = s(o, "Badge");
  return /* @__PURE__ */ t.jsxs("div", { className: `relative ${m}`, children: [
    a,
    r
  ] });
};
l.displayName = "Top";
i.Top = l;
const d = ({
  children: o,
  className: m
}) => /* @__PURE__ */ t.jsx(
  h,
  {
    className: `${m} absolute right-2 top-2`,
    variant: "destructive",
    children: o
  }
);
d.displayName = "Badge";
i.Badge = d;
const x = ({
  src: o,
  width: m = 170,
  height: r = 130,
  className: a = ""
}) => /* @__PURE__ */ t.jsx("div", { className: `${a} px-12 pt-8`, children: /* @__PURE__ */ t.jsx("img", { src: o, width: m, height: r }) });
x.displayName = "Image";
i.Image = x;
const g = ({ children: o, className: m = "" }) => {
  const r = s(o, "Icon"), a = s(o, "Name"), I = s(o, "Info"), y = s(o, "Actions");
  return /* @__PURE__ */ t.jsx("div", { className: `bg-white w-full flex py-3.5 pl-3.5 pr-2.5 ${m}`, children: /* @__PURE__ */ t.jsxs(p, { row: !0, space: "sm", className: "w-full", children: [
    r,
    /* @__PURE__ */ t.jsxs(p, { space: "xs", children: [
      a,
      I
    ] }),
    y
  ] }) });
};
g.displayName = "Bottom";
i.Bottom = g;
const f = ({ name: o }) => /* @__PURE__ */ t.jsx(n, { name: o });
f.displayName = "Icon";
i.Icon = f;
const N = ({ children: o }) => /* @__PURE__ */ t.jsx(c, { size: "sm", children: o });
N.displayName = "Name";
i.Name = N;
const j = ({ children: o }) => /* @__PURE__ */ t.jsx(c, { size: "xs", variant: "secondary", children: o });
j.displayName = "Info";
i.Info = j;
const u = ({ children: o }) => /* @__PURE__ */ t.jsx("span", { className: "ml-auto mb-auto", children: /* @__PURE__ */ t.jsxs(e, { children: [
  /* @__PURE__ */ t.jsx(e.Trigger, { children: /* @__PURE__ */ t.jsx(n, { name: "MoreHoriz", width: 24 }) }),
  o
] }) });
u.displayName = "Actions";
i.Actions = u;
export {
  i as default
};
