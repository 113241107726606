import { CustomizedTableHeader } from "components/dist/organisms/CustomizedTable/types";
import { DropResult } from "react-beautiful-dnd"
import { LoanListViewColumns } from "src/constants/loan"
import { OrderDirection } from "src/constants/ui";
import { useCustomizedTableValues } from 'src/contexts/customized-table-context';
import { useKanbanLoans } from 'src/contexts/loans-kanban-context';
import { LoanTableColumn } from "src/types/loan"

interface LoanListViewTableHook {
    columns: LoanTableColumn[]
    onDragEnd: (result: DropResult) => void
    orderDirection: OrderDirection
    orderByColumn: string
    onSortByColumn: (columnId: string) => void
    onPinLoan: (loanId: string) => void
    selectedHeaders: CustomizedTableHeader[]
    allHeaders: CustomizedTableHeader[]
    toggleHeader: (header: CustomizedTableHeader) => void
}

export const useLoanListViewTable = (): LoanListViewTableHook => {
    const { state, onSetSortedListColumns, onSortByColumn, onPinLoan } = useKanbanLoans();
    const { selectedHeaders, allHeaders, toggleHeader } = useCustomizedTableValues();

    const sortedColumns = sortColumns(state.sortedListViewColumns, LoanListViewColumns);

    const handleDragEnd = (result: DropResult) => {
        if (!result.destination) return;

        const items = [...sortedColumns]
        const [reorderedItem] = items.splice(result.source.index - 1, 1);
        items.splice(result.destination.index - 1, 0, reorderedItem);

        onSetSortedListColumns(items.map(item => item.id));
    }

    return {
        columns: sortedColumns,
        orderByColumn: state.orderByColumn,
        orderDirection: state.orderDirection,
        onDragEnd: handleDragEnd,
        onSortByColumn,
        onPinLoan,
        selectedHeaders,
        allHeaders,
        toggleHeader
    }
}

const sortColumns = (ids: string[], columns: LoanTableColumn[]) => {
    return columns.sort((a, b) => {
        return ids?.indexOf(a.id) - ids?.indexOf(b.id)
    });
}
