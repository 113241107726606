import * as React from "react"

export const BankIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        viewBox="0 0 20 20"
        fill="none"
        {...props}
    >
        <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M2.5 7.915 10 3.332l7.5 4.583m-13.333 8.75h11.666M8.333 7.5h3.334M5 14.165V10m3.333 4.166V10m3.334 4.166V10M15 14.165V10"
        />
    </svg>
)
