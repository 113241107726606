import { styled } from "@mui/material"
import { LoanReviewStatus } from "src/backend"
import { themeV2 } from "src/theme/mysherpas-theme-option"

const Wrapper = styled('div')`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: auto;
    height: 23px;
    border-radius: 100px;
    color: white;
    padding: 3px;
    font-size: 12px;
    padding: 4px 8px;
    font-weight: 400;
    &[data-status="${LoanReviewStatus.READY_FOR_REVIEW_PENDING}"],
    &[data-status="${LoanReviewStatus.HOLD}"],
    &[data-status="${LoanReviewStatus.WITHDRAWN}"] {
            background-color: ${themeV2.neutral.grey[8]};
            color: ${themeV2.neutral.grey[90]};
    }
    &[data-status="${LoanReviewStatus.READY_FOR_REVIEW_REVIEW}"] {
            color: ${themeV2.colors.blue[100]};
            background-color: ${themeV2.colors.blue[10]};
    }
    &[data-status="${LoanReviewStatus.REVIEW_COMPLETE_ACCEPTED}"] {
            background-color: ${themeV2.colors.green[5]};
            color: ${themeV2.colors.green[60]};
    }
    &[data-status="${LoanReviewStatus.REVIEW_COMPLETE_CONDITIONAL}"],
    &[data-status="${LoanReviewStatus.LEAD}"] {
            color: ${themeV2.colors.yellow[800]};
            background-color: ${themeV2.colors.yellow[10]};
    }
    &[data-status="${LoanReviewStatus.REVIEW_COMPLETE_REJECTED}"],
    &[data-status="${LoanReviewStatus.IN_DEFAULT}"] {
            color: ${themeV2.colors.red[50]};
            background-color: ${themeV2.colors.red[10]};
    }
    &[data-status="${LoanReviewStatus.READY_FOR_UNDERWRITING}"] {
            color: ${themeV2.colors.purple[200]};
            background-color: ${themeV2.colors.purple[10]};
    }
    &[data-status="${LoanReviewStatus.IN_SERVICE}"] {
            color: ${themeV2.colors.yellow[400]};
            background-color: ${themeV2.colors.yellow[15]};
    }
    &[data-status="${LoanReviewStatus.ARCHIVE}"] {
        background-color: ${themeV2.neutral.grey[6]};
        color: ${themeV2.colors.black.neutral};
}
    &[data-status="${LoanReviewStatus.CLOSING}"] {
            color: ${themeV2.colors.blue[50]};
            background-color: ${themeV2.colors.blue[5]};
    }
`

export const LoanReviewChipStyles = {
        Wrapper
}