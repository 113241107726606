import { ListItemIcon, ListItemText } from '@mui/material';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import * as React from 'react';
import { LoansKanbanFilter } from 'src/constants/loan';
import { useKanbanLoans } from 'src/contexts/loans-kanban-context';
import { useLenderEmployees } from 'src/hooks/use-lender-employees';
import { BankIcon } from 'src/icons/bank-icon';
import { CommunityIcon } from 'src/icons/community-icon';
import { FilterListIcon } from 'src/icons/filter-list-icon';
import { NavArrowDownIcon } from 'src/icons/nav-arrow-down-icon';
import { PinIcon } from 'src/icons/pin-icon';
import { RemovePinIcon } from 'src/icons/remove-pin-icon';
import { UserBagIcon } from 'src/icons/user-bag-icon';
import { themeV2 } from 'src/theme/mysherpas-theme-option';
import { getUserDisplayName } from 'src/utils/user/get-user-display-name';

import { MenuButton } from '../common/menu-button';
import { LoanFiltersStyles } from './loans-filters/loans-filters.styles';
import { TeammatesSearchCheckbox } from './teammates-search-checkbox';

const loanOptions = [
    {
        icon: <UserBagIcon width={17} height={17} />,
        label: 'My Loans',
        filter: LoansKanbanFilter.LENDER_LOANS
    },
    {
        icon: <BankIcon width={17} height={17} />,
        label: 'All Loans',
        filter: LoansKanbanFilter.COMPANY_LOANS
    },
    {
        icon: <CommunityIcon width={17} height={17} />,
        label: 'Team Loans',
        filter: LoansKanbanFilter.TEAMMATES
    },
    {
        icon: <PinIcon width={17} height={17} />,
        label: 'Pinned',
        filter: LoansKanbanFilter.PINNED
    },
    {
        icon: <RemovePinIcon width={17} height={17} />,
        label: 'Unpinned',
        filter: LoansKanbanFilter.UNPINNED
    },
];
const leadOptions = [
    {
        icon: <UserBagIcon width={17} height={17} />,
        label: 'My Leads',
        filter: LoansKanbanFilter.LENDER_LOANS
    },
    {
        icon: <BankIcon width={17} height={17} />,
        label: 'All Leads',
        filter: LoansKanbanFilter.COMPANY_LOANS
    },
    {
        icon: <CommunityIcon width={17} height={17} />,
        label: 'Team Leads',
        filter: LoansKanbanFilter.TEAMMATES
    },
    {
        icon: <PinIcon width={17} height={17} />,
        label: 'Pinned',
        filter: LoansKanbanFilter.PINNED
    },
    {
        icon: <RemovePinIcon width={17} height={17} />,
        label: 'Unpinned',
        filter: LoansKanbanFilter.UNPINNED
    },
];

interface Props {
    lenderId: string;
    lead?: boolean;
}

export const LoansKanbanFilters = React.memo((props: Props) => {
    const { lenderId, lead } = props;
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const { filterState: { loansFilter, teamMembersIds }, onLoansFilterChange } = useKanbanLoans();
    const { employees } = useLenderEmployees({ lenderId });
    const teamMembersOptions = employees.map((user) => ({ id: user.id, label: getUserDisplayName(user) }));
    const selectedTeamMembersValue = teamMembersIds.map(id => ({ id, label: teamMembersOptions.find((member) => member.id === id)?.label }))
    const options = [...(lead ? leadOptions : loanOptions)];
    const selectedFilterIndex = options.findIndex((option) => option.filter === loansFilter);
    const [selectedIndex, setSelectedIndex] = React.useState(selectedFilterIndex);
    const open = Boolean(anchorEl);

    const handleTeammatesChange = React.useCallback((_event: React.ChangeEvent<{}>, value: { id: string, label: string }[]) => {
        onLoansFilterChange(LoansKanbanFilter.TEAMMATES, value.map((member) => member.id));
    }, [onLoansFilterChange]);

    const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuItemClick = (
        _event: React.MouseEvent<HTMLElement>,
        index: number,
    ) => {
        const option = options[index];
        onLoansFilterChange(option.filter);
        setSelectedIndex(index);
        setAnchorEl(null);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: {
                xs: 'column',
                sm: 'row',
            },
            alignItems: {
                xs: 'flex-start',
                sm: 'center',
            }
        }}>
            <MenuButton
                startIcon={<FilterListIcon />}
                onClick={handleClickListItem}
                endIcon={<NavArrowDownIcon />}>
                {options[selectedIndex].label}
            </MenuButton>
            {selectedIndex === 2 && (<TeammatesSearchCheckbox
                value={selectedTeamMembersValue}
                onChange={handleTeammatesChange}
                options={teamMembersOptions} />)}
            <Menu
                id="filter-loans-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'filter-loans',
                    role: 'listbox',
                    sx: {
                        minWidth: {
                            sm: 200,
                        }
                    }
                }}
            >
                {options.map((option, index) => (
                    <MenuItem
                        key={option.label}
                        selected={index === selectedIndex}
                        onClick={(event) => handleMenuItemClick(event, index)}>
                        <ListItemIcon
                            sx={{
                                color: themeV2.colors.blue[900]
                            }}>
                            {option.icon}
                        </ListItemIcon>
                        <ListItemText primary={option.label} />
                    </MenuItem>
                ))}
            </Menu>
        </Box>
    );
});

LoansKanbanFilters.displayName = 'LoansKanbanFilters';