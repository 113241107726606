import { Box, Card, CardContent, CardHeader, Chip, Divider, Tooltip, Typography } from '@mui/material'
import { memo } from 'react';
import { MAX_LOAN_TITLE_LENGTH, RoleType } from 'src/constants/loan';
import { Loan } from 'src/types/loan';
import { getLoanApplicant } from 'src/utils/loan/get-loan-applicant';
import { getUserDisplayName } from 'src/utils/user/get-user-display-name';

import { MysherpasTooltip } from './common/mysherps-tooltip';
import { LoanBlurbText } from "./loans/loan-blurb-text";
import { LoanProgressBar } from './loans/loan-progress-bar';
import { LoanReviewChip } from './loans/loan-review-chip';
import { RolesAvatars } from './loans/roles-avatars';

interface LoanCardProps {
    loan: Loan;
    isUserALender?: boolean;
}

export const LoanCard = memo((props: LoanCardProps) => {
    const { loan, isUserALender, ...other } = props;

    const applicant = getLoanApplicant(loan.loanEntities);
    const leadLender = loan.loanRoles.find(role => role.role === RoleType.LeadLender)?.user;

    return <Card
        {...other}
        sx={{
            width: '100%',
            boxShadow: 0,
            mt: 3,
            overflow: 'visible',
            display: 'flex',
            flexDirection: 'column',
        }}
        id={`loan-card-${loan.projectName.replace(/\s+/g, '-')}` /* for selenium automation */}>
        <CardHeader
            action={<Box sx={{
                display: 'flex',
                alignItems: 'center'
            }}>
                <Tooltip
                    title='Loan ID'
                    placement='top' arrow disableInteractive>
                    <Typography variant={"subtitle2"}>
                        ID: <span style={{ fontFamily: "andale mono, monaco, monospace" }}>{loan.shortCode?.toUpperCase()}</span>
                    </Typography>
                </Tooltip>
                {!isUserALender && <Chip data-testid='loan-status-chip' color='success' label={loan.loanPhase?.name} size='small' sx={{ fontSize: 10, ml: 1 }} />}
            </Box>}
            title={!!applicant ? applicant.sherpaEntity.name : "TBD"}
            subheader={<Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                <MysherpasTooltip title={props.loan.projectName}>
                    <span>
                        {loan.projectName.length > MAX_LOAN_TITLE_LENGTH ? loan.projectName.substring(0, MAX_LOAN_TITLE_LENGTH) + '...' : loan.projectName}
                    </span>
                </MysherpasTooltip>
            </Box>}
            titleTypographyProps={{
                variant: 'subtitle2',
                sx: {
                    textTransform: 'capitalize',
                }
            }}
            sx={{
                pb: 1,
                position: 'relative',
                zIndex: 2,
                px: 2,
                pt: 1,
                '& .MuiCardHeader-content': {
                    overflow: 'hidden',
                }
            }} />
        <CardContent
            sx={{
                flex: 1,
                position: 'relative',
                zIndex: 2,
                pt: 0,
                '&:last-child': {
                    pt: 0
                },
                px: 2,
            }}>
            <Box sx={{ mb: 1 }}>
                <LoanBlurbText shorten loan={loan} />
            </Box>
            <Typography variant="subtitle2" color='secondary.main'>
                {(!isUserALender && leadLender) && ` LO: ${getUserDisplayName(leadLender)}`}
            </Typography>
            <LoanReviewChip status={props.loan.reviewStatus} />
        </CardContent>

        <Box sx={{ p: 2, gap: 2, display: 'flex', flexDirection: 'column' }}>
            <Divider />
            <RolesAvatars roles={loan.loanRoles} />
            <LoanProgressBar loan={props.loan} />
        </Box>
    </Card>
});




LoanCard.displayName = 'LoanCard';
