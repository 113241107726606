import { useEffect } from 'react';
import { LoanPhaseCategoryType } from 'src/backend';
import { useMounted } from 'src/hooks/use-mounted';
import { checkLoanCategoryHasLoans, getLoans, loansSelector } from 'src/slices/loan-manager';
import { loanStagesSelector } from 'src/slices/loan-stages';
import { useDispatch, useSelector } from 'src/store';
import { Loan } from 'src/types/loan';
import { intervalManager } from 'src/utils';

type ReturnValue = {
    allLoans: Loan[];
};

interface UseLoanProps {
    fetchLoans: boolean;
}

export const useLoans = (props: UseLoanProps): ReturnValue => {
    const isMounted = useMounted();
    const dispatch = useDispatch();
    const phases = useSelector(loanStagesSelector);
    const allLoans = useSelector(loansSelector);

    useEffect(() => {
        if (isMounted() && props.fetchLoans) {
            intervalManager.set('geLoans', () => {
                phases.forEach((category) => {
                    dispatch(getLoans({
                        page: 1,
                        phaseId: category.id,
                    }));
                });
            }, true);
            intervalManager.set('checkLoanCategoryHasLoans', () => {
                Object.keys(LoanPhaseCategoryType).forEach((category) => {
                    dispatch(checkLoanCategoryHasLoans(category as LoanPhaseCategoryType));
                });
            });
        }
        return () => {
            intervalManager.clear('geLoans');
            intervalManager.clear('checkLoanCategoryHasLoans');
        }
    }, [dispatch, isMounted, phases, props.fetchLoans])

    return {
        allLoans
    };
}