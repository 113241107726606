import IconButton from "@mui/material/IconButton";
import LoanCard from 'components/dist/organisms/LoanCard';
import React from "react";
import { JestTestId } from "src/constants/tests";
import { useCustomizedTableValues } from "src/contexts/customized-table-context";
import { InfoEmptyIcon } from 'src/icons/info-empty-icon';
import { PinIcon } from "src/icons/pin-icon";
import { Loan, LoanTableColumn } from "src/types/loan";

import { LoanReviewIcon } from "../loan-review-icon";
import { LoanOverviewCardStyles } from "../tabs/overview/v2/loan-overview-card/loan-overview-card.styles";
import { LoanListViewTableStyles } from "./loan-list-view-table.styles";
import { useLoanListViewTableRow } from "./loan-list-view-table-row.hook";

interface LoanListViewTableRowProps {
    loan: Loan;
    columns: LoanTableColumn[];
    isPinned: boolean;
    onPinLoan: (loanId: string) => void;
}
export const LoanListViewTableRow = (props: LoanListViewTableRowProps) => {
    const {
        columns,
        onRowClick
    } = useLoanListViewTableRow({
        loan: props.loan,
        columns: props.columns
    });

    const { selectedHeaders, allHeaders } = useCustomizedTableValues();
    const loanInfoColumns = allHeaders
      .filter((header) => !selectedHeaders.find((selectedHeader) => selectedHeader.id === header.id));
    const { columns: loanInfoColumnsWithValues } = useLoanListViewTableRow({ loan: props.loan, columns: loanInfoColumns as LoanTableColumn[] });

    const handlePinClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        props.onPinLoan(props.loan.id)
    }

    return <LoanListViewTableStyles.TableRow
        key={props.loan.id}
        onClick={onRowClick}>
        <LoanListViewTableStyles.TableCell data-align-center="true">
            <LoanReviewIcon status={props.loan.reviewStatus} />
            <LoanOverviewCardStyles.LightTooltip
                placement={'bottom-start'}
              title={
                <LoanCard>
                  {loanInfoColumnsWithValues.map((column) => (
                    <LoanCard.Item key={column.id} className={column.id === 'loanProgress' ? '[&>div]:flex-col' : ''}>
                      <LoanCard.Title>{column.label}</LoanCard.Title>
                      <LoanCard.Value className={`truncate ${column.id === 'loanProgress' ? 'mt-2 mb-2' : ''}`}>
                        {column.formattedValue}
                      </LoanCard.Value>
                    </LoanCard.Item>
                  ))}
                </LoanCard>
              }
            >
                <LoanListViewTableStyles.InfoIconButton data-testid={JestTestId.LoanListTableRowInfoButton}>
                    <InfoEmptyIcon width={20} height={20} />
                </LoanListViewTableStyles.InfoIconButton>
            </LoanOverviewCardStyles.LightTooltip>
            <IconButton
                data-testid={JestTestId.LoanListViewTableRowPinIconButton}
                className={`pin-button ${props.isPinned ? 'is-pinned' : 'is-unpinned'}`}
                onClick={handlePinClick}>
                <PinIcon />
            </IconButton>
        </LoanListViewTableStyles.TableCell>
        {columns.map((column) => (
            <LoanListViewTableStyles.TableCell
                align={column.align || 'left'}
                key={column.id}>
                {column.formattedValue}
            </LoanListViewTableStyles.TableCell>
        ))}
    </LoanListViewTableStyles.TableRow>
};
