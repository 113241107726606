import { Add } from '@mui/icons-material';
import { Menu, MenuItem } from '@mui/material';
import { CustomizedTable } from 'components/dist/organisms/CustomizedTable';
import NextLink from 'next/link';
import { MenuButton } from 'src/components/common/menu-button';
import { LoanRoleGuard } from 'src/components/user/loan-role-guard';
import {
  LoanListViewFixedColumns,
  LoanStageCategory,
  QUERY_PARAM_PHASE_CATEGORY,
} from 'src/constants/loan';
import { Route } from 'src/constants/ui';
import { Permission } from 'src/constants/user';
import { Table2ColumnsIcon } from 'src/icons/table-2-columns-icon';
import { ViewGridIcon } from 'src/icons/view-grid-icon';
import { LoanDisplayView } from 'src/types/loan';

import { LoansKanbanFilters } from '../loans-kanban-filters';
import { useLoanFilters } from './loans-filters.hook';
import { LoanFiltersStyles } from './loans-filters.styles';

interface LoansFiltersProps {
  lenderId: string;
}

export const LoansFilters = (props: LoansFiltersProps) => {
  const {
    displayView,
    sortBy,
    sortItems,
    onDropdownButtonClick,
    onSortByClick,
    onLoansDisplayViewChange,
    isDropdownOpen,
    dropdownAnchorEl,
    navbarTitle,
    isLeadPhase,
    onDropdownClose,
    selectedHeaders,
    toggleHeader,
    allHeaders,
    onReset
  } = useLoanFilters();

  return (
    <>
      <LoanFiltersStyles.Container>
        <LoanFiltersStyles.Title>{navbarTitle}</LoanFiltersStyles.Title>
        <LoansKanbanFilters lenderId={props.lenderId} />
        {/* {displayView === LoanDisplayView.list && <LoanFiltersStyles.Button
        onClick={onDropdownButtonClick}
        startIcon={<DataTransferBothIcon />}>
        Sort by
      </LoanFiltersStyles.Button>} */}
        {/* <LoanFiltersStyles.Button startIcon={<GridAddIcon />}>
      Customize
    </LoanFiltersStyles.Button> */}
        <CustomizedTable.Trigger
          className="p-1 ml-5 mr-8"
          allHeaders={allHeaders}
          selectedHeaders={selectedHeaders}
          toggleHeader={toggleHeader}
          fixedHeaders={LoanListViewFixedColumns}
          onReset={onReset}
        />
        <LoanFiltersStyles.ViewButtonGroup
          size="small"
          disableElevation
          aria-label="Disabled elevation buttons"
        >
          <MenuButton
            onClick={() => onLoansDisplayViewChange(LoanDisplayView.grid)}
            data-active={displayView === LoanDisplayView.grid}
            startIcon={<ViewGridIcon />}
          >
            Card view
          </MenuButton>
          <MenuButton
            onClick={() => onLoansDisplayViewChange(LoanDisplayView.list)}
            data-active={displayView === LoanDisplayView.list}
            startIcon={<Table2ColumnsIcon />}
          >
            Table view
          </MenuButton>
        </LoanFiltersStyles.ViewButtonGroup>
        <LoanRoleGuard permission={Permission.CrudLoan}>
          <NextLink
            href={{
              pathname: Route.CREATE_LOAN,
              query: {
                ...(isLeadPhase && {
                  [QUERY_PARAM_PHASE_CATEGORY]: LoanStageCategory.LEAD,
                }),
              },
            }}
            passHref
          >
            <LoanFiltersStyles.Link>
              <Add fontSize="small" /> Start {isLeadPhase ? 'Lead' : 'Loan'}
            </LoanFiltersStyles.Link>
          </NextLink>
        </LoanRoleGuard>
      </LoanFiltersStyles.Container>
      <Menu
        onClose={onDropdownClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        anchorEl={dropdownAnchorEl}
        open={Boolean(isDropdownOpen)}
      >
        {sortItems.map((item) => (
          <MenuItem
            selected={item.value === sortBy}
            key={item.value}
            onClick={() => onSortByClick(item.value)}
          >
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

