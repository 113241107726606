import { Skeleton } from "@mui/material"
import { LoanPhaseDto } from "src/backend"
import { useLoanKanbanPhase } from "src/components/loan-kanban-phase/loan-kanban-phase.hook"
import { LoanTableColumn } from "src/types/loan"

import { LoanListViewTableStyles } from "./loan-list-view-table.styles"

interface LoanListViewRowSkeletonProps {
    columns: LoanTableColumn[]
    loanPhase: LoanPhaseDto
}

export const LoanListViewRowSkeleton = (props: LoanListViewRowSkeletonProps) => {
    const {
        isLoading,
        loadMoreNode
    } = useLoanKanbanPhase({
        loanPhase: props.loanPhase,
    })

    return <LoanListViewTableStyles.TableRow
        ref={loadMoreNode}
        sx={{ opacity: isLoading ? 1 : 0, visibility: isLoading ? "visible" : "collapse" }}>
        <LoanListViewTableStyles.TableCell>
            <Skeleton variant="text" />
        </LoanListViewTableStyles.TableCell>
        <LoanListViewTableStyles.TableCell>
            <Skeleton variant="text" />
        </LoanListViewTableStyles.TableCell>
        {props.columns.map((column) => (
            <LoanListViewTableStyles.TableCell
                align={column.numeric ? 'right' : 'left'}
                key={column.id}>
                <Skeleton variant="text" />
            </LoanListViewTableStyles.TableCell>
        ))}
    </LoanListViewTableStyles.TableRow>
}