import { PushPin } from '@mui/icons-material';
import {
    Box,
    IconButton,
    Paper,
    Tooltip
} from '@mui/material';
import NextLink from 'next/link'
import { Draggable, Droppable } from "react-beautiful-dnd";
import { LoanPhaseDto, ViewType } from 'src/backend';
import { LENDER_VIEW } from 'src/constants/person';
import { JestTestId } from 'src/constants/tests';
import { Route } from 'src/constants/ui';
import { themeV2 } from 'src/theme/mysherpas-theme-option';
import { Loan } from 'src/types/loan';

import { LoanCard } from '../loan-card';
import { LoansKanbanCardSkelton } from '../loans-kanban/loans-kanban-card-skeleton';
import { useLoanKanbanPhase } from './loan-kanban-phase.hook';
import { LoanKanbanPhaseStyles } from './loan-kanban-phase.styles';

interface LoansColumnProps {
    isDragEnabled?: boolean;
    loans: Loan[];
    viewType: ViewType;
    loanPhase: LoanPhaseDto;
    className?: string;
    onPinLoan?: (loanId: string) => void;
}

export const getKanbanColumnSx = (isDraggingOver: boolean) => (theme) => ({
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    mb: 2,
    px: 1,
    pt: 2,
    overflow: 'auto',
    height: 'calc(100% - 84px)',
    pb: isDraggingOver ? 40 : 2,
    display: 'flex',
    backgroundColor: themeV2.neutral.grey[6],
    boxShadow: {
        xs: 'none',
    },
    flexShrink: 0,
    transition: theme.transitions.create('backgroundColor', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    }),
    ...(isDraggingOver && {
        backgroundColor: themeV2.colors.blue[10],
        transition: theme.transitions.create('backgroundColor', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        })
    })
});

const getItemSx = (isDragging: boolean, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    // change background colour if dragging
    '& > .MuiPaper-root': {
        boxShadow: isDragging ? 10 : 1,
    },
    // styles we need to apply on draggables
    ...draggableStyle
});


export const LoanKanbanPhase = (props: LoansColumnProps) => {
    const state = useLoanKanbanPhase({
        loanPhase: props.loanPhase,
    })
    return (
        <>
            {/* @ts-expect-error */}
            <Droppable
                droppableId={props.loanPhase.id}
                isDropDisabled={!props.isDragEnabled}>
                {(provided, droppableSnapshot) => (
                    <Paper
                        key={props.loanPhase.id}
                        className={props.className}
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        sx={getKanbanColumnSx(droppableSnapshot.isDraggingOver)}>
                        {props.loans.map((loan, index) => (
                            // @ts-expect-error
                            <Draggable
                                isDragDisabled={!props.isDragEnabled}
                                key={loan.id}
                                draggableId={loan.id}
                                index={index}>
                                {(provided, draggableSnapshot) => (
                                    <Box
                                        className='loan-card-container'
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        sx={{
                                            width: '100%',
                                            position: 'relative',
                                            '&:hover > button': {
                                                display: {
                                                    xs: 'none',
                                                    sm: (droppableSnapshot.isDraggingOver) ? 'none' : 'flex'
                                                }
                                            },
                                            ...getItemSx(
                                                draggableSnapshot.isDragging,
                                                provided.draggableProps.style
                                            )
                                        }}>
                                        <Tooltip title="Pin to top" arrow disableInteractive>
                                            <IconButton
                                                onClick={() => props.onPinLoan?.(loan.id)}
                                                sx={{
                                                    display: {
                                                        xs: 'none',
                                                        sm: (loan.pinned && !droppableSnapshot.isDraggingOver) ? 'flex' : 'none'
                                                    },
                                                    backgroundColor: loan.pinned ? 'warning.main' : 'white',
                                                    color: loan.pinned ? 'white' : 'text.main',
                                                    borderRadius: '50%',
                                                    zIndex: 3,
                                                    position: 'absolute',
                                                    top: 0,
                                                    p: .5,
                                                    boxShadow: 1,
                                                    right: 0,
                                                    transform: 'translate(25%, -25%)',
                                                    ...(!loan.pinned ? {
                                                        '&:hover': {
                                                            backgroundColor: 'primary.dark',
                                                            color: 'white',
                                                            boxShadow: 2
                                                        }
                                                    } : {
                                                        '&:hover': {
                                                            backgroundColor: 'warning.main',
                                                            color: 'white',
                                                            boxShadow: 2
                                                        }
                                                    })
                                                }}>
                                                <PushPin fontSize='small' />
                                            </IconButton>
                                        </Tooltip>
                                        <NextLink
                                            href={{ pathname: Route.SINGLE_LOAN, query: { loanId: loan.id } }}
                                            passHref>
                                            <LoanKanbanPhaseStyles.LoanCardLink>
                                                <LoanCard
                                                    loan={loan}
                                                    isUserALender={props.viewType === LENDER_VIEW}
                                                />
                                            </LoanKanbanPhaseStyles.LoanCardLink>
                                        </NextLink>
                                    </Box>
                                )}
                            </Draggable>
                        ))}
                        {/* @ts-expect-error */}
                        <Draggable
                            isDragDisabled
                            key={`loading-more-placeholder-${props.loans.length}`}
                            draggableId="loading-more-placeholder"
                            index={999_999_999_999}>
                            {(provided) => (<div ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}>
                                <div
                                    data-testid={JestTestId.LoanKanbanPhaseLoadingMorePlaceholder}
                                    ref={state.loadMoreNode} />
                            </div>)}
                        </Draggable>
                        {/* @ts-expect-error */}
                        <Draggable
                            isDragDisabled
                            key="skeleton-key"
                            draggableId="skeleton-key"
                            index={999_999_999_998}>
                            {(provided) => (
                                <Box
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={{ opacity: state.isLoading ? '1' : '0' }}
                                    className='loan-card-container'>
                                    <LoansKanbanCardSkelton />
                                </Box>
                            )}
                        </Draggable>
                        {provided.placeholder}

                    </Paper>
                )}
            </Droppable>

        </>
    );
};
