import * as React from "react"

export const PinIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={21}
        height={20}
        viewBox="0 0 21 20"
        fill="none"
        {...props}
    >
        <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="m8.098 12.084-5.416 5.417m1.666-9.596 7.661 7.66 1.414-1.413-.327-3.156 4.591-3.894-4.875-4.875-3.895 4.591-3.155-.327-1.414 1.414Z"
        />
    </svg>
)
