import { CardHeader as MuiCardHeader, Chip as MuiChip, Container as MuiContainer, styled } from "@mui/material"
import { themeV2 } from "src/theme/mysherpas-theme-option"

const Wrapper = styled('div')(({ theme }) => ({
    backgroundColor: themeV2.neutral.grey[7],
    minHeight: '100vh',
    paddingTop: theme.spacing(4),
    '&[data-hide-statuses="true"]': {
        '& .loan-ready-for-review-icon-wrapper': {
            display: 'none',
            visibility: 'hidden',
        }
    }
}))

const CardHeader = styled(MuiCardHeader)(({ theme }) => ({
    padding: theme.spacing(2),
    '& .MuiCardHeader-title': {
        fontSize: theme.typography.pxToRem(14),
        fontWeight: 500,
        gap: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
    }
}))

const Container = styled(MuiContainer)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
}))

const Chip = styled(MuiChip)(({ theme }) => ({
    fontSize: theme.typography.pxToRem(12),
    color: themeV2.colors.blue[900],
    minWidth: 20,
    height: 20,
}))

export const LoanListViewStyles = {
    Wrapper,
    CardHeader,
    Container,
    Chip
}