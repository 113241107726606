import { Button as MuiButton, ButtonGroup, Link as MuiLink, styled } from "@mui/material"
import { themeV2 } from "src/theme/mysherpas-theme-option"

const Container = styled('div')(({ theme }) => ({
    backgroundColor: 'white',
    flex: 1,
    display: 'flex',
    padding: theme.spacing(2.5, 4),
    alignItems: 'center',
}))

const Title = styled('h1')(({ theme }) => ({
    fontSize: theme.typography.pxToRem(20),
    flex: 1,
    fontWeight: 400,
    margin: 0,
}))

export const Link = styled(MuiLink)(({ theme }) => ({
    padding: theme.spacing(1, 2),
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 500,
    backgroundColor: themeV2.colors.blue[100],
    color: 'white',
    borderRadius: theme.spacing(1),
    alignItems: 'center',
    display: 'flex',
    gap: theme.spacing(.5),
    '&:hover': {
        textDecoration: 'none',
        backgroundColor: themeV2.colors.blue[800],
    }
}))

const ViewButtonGroup = styled(ButtonGroup)(({ theme }) => ({
    marginRight: theme.spacing(4),
}))

export const LoanFiltersStyles = {
    Container,
    Title,
    Link,
    ViewButtonGroup
}