import { IconButton, styled, Table as MuiTable, TableCell as MuiTableCell, TableRow as MuiTableRow } from "@mui/material";
import { themeV2 } from "src/theme/mysherpas-theme-option";

const TableCell = styled(MuiTableCell)(({ theme }) => ({
    '&.MuiTableCell-root': {
        width: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        boxSizing: 'content-box',
        fontSize: theme.typography.pxToRem(12),
        fontWeight: theme.typography.fontWeightRegular,
        textTransform: 'none',
        color: themeV2.colors.blue[900],
        whiteSpace: 'nowrap',
        // paddingLeft: theme.spacing(1),
        // paddingRight: theme.spacing(1),
        '&[data-limited-width]': {
            width: '100px',
        },
        '&[data-width=full]': {
            width: '100%',
        },
        '&[data-width=content]': {
            width: 'fit-content',
        },
        '&[data-align-center]': {
            alignItems: 'center',
            display: 'flex',
        },
        '&[data-center]': {
            textAlign: 'center'
        },
        '&[data-right]': {
            textAlign: 'right'
        },
        '&[data-medium]': {
            fontSize: theme.typography.pxToRem(14),
            fontWeight: theme.typography.fontWeightMedium,
        },
        '&[data-bolder]': {
            fontWeight: theme.typography.fontWeightMedium,
        }
    }
}))

const TableRow = styled(MuiTableRow)(({ theme }) => ({
    '&.MuiTableRow-root': {
        cursor: 'pointer',
        borderRadius: 0,
        '&[data-head]': {
            backgroundColor: themeV2.neutral.grey[6],
            '& .MuiTableCell-root': {
                fontWeight: theme.typography.fontWeightMedium,
            }
        },
        '&:hover:not([data-head])': {
            backgroundColor: themeV2.colors.blue[10],
        }
    },
    '& .pin-button': {
        '&.is-unpinned': {
            opacity: 0
        },
    },
    '&:hover': {
        '& .pin-button': {
            '&.is-unpinned': {
                opacity: .3,
                '&:hover': {
                    opacity: 1
                }
            }
        }
    }
}))

const InfoIconButton = styled(IconButton)(({ theme }) => ({
    '&.MuiIconButton-root': {
        color: themeV2.colors.blue[100],
    }
}))

const Table = styled(MuiTable)(({ theme }) => ({
    '&.MuiTable-root': {
        minWidth: 650,
        tableLayout: 'auto',
        '&[data-table-fixed]': {
            tableLayout: 'fixed',
        }
    }
}))

export const LoanListViewTableStyles = {
    TableCell,
    TableRow,
    InfoIconButton,
    Table
};
