import * as React from "react"

export const RemovePinIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        viewBox="0 0 20 20"
        fill="none"
        {...props}
    >
        <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M7.917 12.084 2.5 17.501M6.397 6.576 5.58 6.49 4.167 7.905l7.66 7.66 1.415-1.413-.085-.818m-3.586-7.5 3.06-3.607 4.875 4.875-3.59 3.045M2.5 2.5l15 15"
        />
    </svg>
)
