import { styled } from "@mui/material/styles";
import MuiTextField, { TextFieldProps } from "@mui/material/TextField";
import { themeV2 } from "src/theme/mysherpas-theme-option";

const TextField = styled(MuiTextField)(({ theme }) => ({
    '& .MuiOutlinedInput-notchedOutline legend': {
        display: 'none'
    },
    '& .MuiOutlinedInput-input': {
        zIndex: 1,
    },
    '& .MuiInputLabel-root': {
        position: 'relative',
        transform: 'none',
        fontSize: theme.typography.pxToRem(14),
        fontWeight: theme.typography.fontWeightRegular,
        paddingBottom: theme.spacing(1),
    },
    '& .Mui-disabled, &[data-readonly="true"], &[readonly]': {
        cursor: 'not-allowed',
        '& .MuiOutlinedInput-input': {
            color: 'rgba(55, 65, 81, 0.48)',
        },
        '& .MuiOutlinedInput-input:hover': {
            cursor: 'not-allowed'
        },
        '& .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-root:hover': {
            borderColor: themeV2.neutral.grey[80],
            backgroundColor: themeV2.neutral.grey[10],
            cursor: 'not-allowed'
        },
        '& .MuiInputAdornment-root': {
            zIndex: 1,
        }
    },
    "& .MuiInputBase-formControl .MuiInputBase-input::placeholder": {
        // placeholder styling
        fontSize: theme.typography.pxToRem(16),
        fontWeight: theme.typography.fontWeightRegular,
    },
    '& .MuiAutocomplete-endAdornment': {
        zIndex: 10,
        backgroundColor: 'white',
    },
    '& .MuiInputBase-adornedStart svg': {
        marginRight: theme.spacing(1.3),
    }
}));

const TextFieldGroup = styled('div')(({ theme }) => ({
    position: 'relative',
    display: 'flex',
    alignItems: 'flex-start',
    '& > div:first-of-type:not(:last-of-type) .MuiOutlinedInput-notchedOutline': {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        borderRightWidth: 0
    },
    '& > div:last-of-type:not(:first-of-type) .MuiOutlinedInput-notchedOutline': {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
    },
    '& > div:last-of-type:not(:nth-of-type(2), :first-of-type) .MuiOutlinedInput-notchedOutline': {
        borderLeftWidth: 0
    },
    '& > div:nth-of-type(2):not(:last-of-type) .MuiOutlinedInput-notchedOutline': {
        borderRadius: 0,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
    },
    '& > div:nth-of-type(3):not(:last-of-type) .MuiOutlinedInput-notchedOutline': {
        borderRadius: 0,
    },
    '& > div': {
        flex: 1,
    },
    '& .MuiButtonBase-root': {
        '&[data-reset="true"]': {
            zIndex: 2,
            backgroundColor: 'white',
            padding: theme.spacing(.5),
            fontSize: theme.typography.pxToRem(12),
            position: 'absolute',
            right: theme.spacing(1.5),
            top: theme.spacing(4),
        }
    },

}));

export const FormikedTextFieldStyles = {
    TextFieldGroup,
    TextField: (props: TextFieldProps) => (<TextField
        {...props}
        InputLabelProps={{
            ...props.InputLabelProps,
            shrink: true,
        }}
        {
        ...props.type !== 'file' && {
            value: props.value ?? ""
        }
        }
        SelectProps={{
            ...props.SelectProps,
            native: true,
        }}
        variant="outlined" />),
}